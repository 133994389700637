

body::-webkit-scrollbar {
  width: 7px;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #14AA72;
  outline: 1px solid #14AA72;
  border-radius: 200px;
}
.mobile-nav-active .mobile-nav-toggle {
  display:block !important;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 transparent;
}

.card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #0000001c;
  border: none;
  border-radius: 15px;
 padding: 30px;
}
.text-left {
  text-align: left;
}
.boxshadow-0{
  box-shadow: none;
}
.pl-0 {
  padding-left: 0;
}
hr {
  border-top: 1px solid grey;
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
}

/* INNER PAGE HEADER */
section.tsd__details {
  padding: 0;
  position: relative;
  height: 340px;
  background-size: cover;
  background-repeat: no-repeat;
  top: 55px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

section.tsd__details:after {
  content: "";
  position: absolute;
  left: 0;
  background: #00000061;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

section.tsd__details .page__title {
  position: relative;
  z-index: 9999;
  text-align: center;
}
section.tsd__details .page__title h2{
  color: #fff;
  position: relative;
  display: initial;
  padding-bottom: 5px;
}
section.tsd__details .page__title h2:after {
  content: "";
  position: absolute;
  right: 0;
  background: var(--color-primary);
  width: 100%;
  height: 8px;
  bottom: 0;
  border-radius: 100px;
}

section.tsd__details .page__title .icon {
  padding-bottom: 10px;
}

section.tsd__details .page__title .icon img {
  height: 55px;
}


/* CALENDAR DETAILS */
section.upcoming__events .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: transparent;
  border: 2px solid #14AA72;
  border-radius: 13px;
  opacity: 1;
  padding: 20px 30px;
}


section.upcoming__events .tab__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section.upcoming__events .title h2 {
    color: #2E2E2E;
    opacity: 1;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 6px;
}

section.upcoming__events .title p {
    color: #BFBFBF;
    opacity: 1;
    font-size: 14px;
    margin: 0;
}

section.upcoming__events .left__btn button.btn-time {
  background: #14AA72 0% 0% no-repeat padding-box;
  opacity: 1;
  border: 0;
  color: #fff;
  padding: 5px 20px;
  margin-left: 10px;
  border-radius: 3px;
  min-height: 37px;
}

section.upcoming__events .left__btn button.btn-arrow {
  background: #2E2E2E 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #2E2E2E4D;
  border-radius: 3px;
  opacity: 1;
  padding: 3px 15px;
  border-radius: 3px;
  min-height: 37px;
}
section.upcoming__events .nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
  border: 2px solid transparent;
  border-radius: 13px;
  opacity: 1;
  padding: 20px 30px;
}

section.upcoming__events .tab__content .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}

section.upcoming__events .tab__content .date__time {
  display: flex;
  align-items: center;
}

section.upcoming__events .tab__content .date__time p {
  margin: 0;
  color: #14AA72;
  font-weight: 700;
  font-size: 22px;
  margin: 0 10px;
}

section.upcoming__events .tab__content .tab__content .header img {
  height: 51px;
}

section.upcoming__events .tab__content {
  border: 2px solid #14AA72;
  border-radius: 13px;
  opacity: 1;
  padding: 22px 40px;
}

section.upcoming__events .tab__content .decription h4 {
  font-weight: bold;
}

section.upcoming__events .tab__content .decription p {
  letter-spacing: 0px;
  color: #BFBFBF;
  opacity: 1;
  margin: 0;
}
section.upcoming__events .tab__content .header img {
  height: 54px;
}

.trainingGrids{
  transition: all .1s ease-in;
  border-radius: 0px;
  border: 2px solid transparent !important;
  padding: 15px !important;
  border-bottom: 2px solid #eceff1 !important;
  padding-bottom: 30px !important;
 
}
.trainingGrids:hover{
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #11AE21 !important;
  border-radius: 10px;
  cursor: pointer;
  transition: opacity .10s ease-in-out;
  -moz-transition: opacity .10s ease-in-out;
  -webkit-transition: opacity .10s ease-in-out;
  transition: all ease-in-out 0.2s;
  padding: 15px !important;
  padding-bottom: 30px !important;
}

.homeCalendarSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 28px;
  right: 0;
  z-index: 1;
  top: auto;
}

button.btn.btn-calendarsearch {
  background: #14aa72;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: #fff;
}

.homeCalendarSearch .form-control {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  width: 184px;
}
.homeCalendarSearch .form-control:focus{
  border: 1px solid #eceff1;
}
.calendar__select {
  position: absolute;
  left: 30px;
  z-index: 1;
}
.calendar__select select.form-select {
  border: none;
  font-size: 19px;
  color: #adb5bd;
  padding-left: 0;
}
.form-select:focus{
  outline: none !important;
  box-shadow: none !important;
  border: none;
}

/* RESPONSVE */
button.navbar-toggler{
  border: none;
  color: var(--color-primary);
}
button.navbar-toggler:focus{
  border: none;
  outline: none;
  box-shadow: none;
}