/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
section.image__gallery .gallery__row .gallery__image {
    overflow: hidden;
    cursor: pointer;
}
section.image__gallery.gallery__listing .date p{
    font-size: 17px;
}
section.image__gallery.gallery__listing .date {
    width: 63px;
    height: 58px;
}
section.image__gallery.gallery__listing {
    margin-top: 50px;
}
section.image__gallery.gallery__listing .gallery__image img {
    height: 200px;
}

section.image__gallery .gallery__row .gallery__image img {
    transition: all ease-in-out 0.4s;
}

section.image__gallery .gallery__row .gallery__image:hover img {
    transform: scale(1.1);
}

section.image__gallery .gallery__image {
    position: relative;
}

section.image__gallery .image {
    display: block;
    width: 100%;
    height: auto;
}

section.image__gallery .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #0000007a;
}

section.image__gallery .gallery__image:hover .date {
    opacity: 0;
}

section.image__gallery .gallery__image:hover .overlay {
    opacity: 1;
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
    border-color: #14aa72;
    border-width: thick;
}