.fc .fc-toolbar {
    flex-flow: row-reverse;
    font-family: var(--font-primary);
}
/* section.detailed__calendar thead {
    display: none;
} */
.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5em;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 40px;
}
button.fc-search-button.fc-button.fc-button-primary {
    padding: 0;
    font-size: 18px;
    font-weight: 900;
    margin-left: 20px;
}

button.fc-addEvent-button.fc-button.fc-button-primary {
    padding: 0;
    font-size: 20px;
    margin: 0;
}

.fc-toolbar-chunk {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin: 0 22px;
}
.fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #ddd;
}
.fc-theme-standard .fc-scrollgrid{
    border: none;
}
.fc .fc-daygrid-day-number {
    position: relative;
    z-index: 4;
    padding: 4px;
    font-size: 25px;
    font-weight: 700;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    position: relative;
    min-height: auto;
    display: flex;
}
.fc-daygrid-event-dot {
    margin: 0px;
    box-sizing: content-box;
    width: 0;
    height: 0;
    border: 4px solid #B100FF;
    border-radius: 4px;
    border-radius: calc(var(--fc-daygrid-event-dot-width, 8px) / 2);
}
.fc .fc-daygrid-day.fc-day-today{
    background-color: var(--color-primary);
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number{
    color: #fff;
    font-weight: bold !important;
}
.fc-daygrid-day-number{
    color:#2E2E2E;
    font-weight: normal !important;
}
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
    display: none;
}

.fc-event-title {
    display: none;
}
.fc .fc-daygrid-day-bottom {
    font-size: .85em;
    padding: 0;
}
.fc .fc-toolbar-title {
    font-size: 30px;
    margin: 0;
    font-weight: bold;
}
.fc .fc-button-primary {
    color: #2C3E50;
    color: #2C3E50;
    background-color: transparent;
    border-color: none !important;
    border-color: none !important;
    padding: 3px;
    border-color: transparent;
    width: 32px;
    height: 32px;
}
button.fc-next-button.fc-button.fc-button-primary {
    background: #2e2e2e;
    border-radius: 5px !important;
    color: #fff;
    margin-left: 10px !important;
    width: 32px;
    height: 32px;
}
.fc-button-primary:hover{
    background: transparent !important; 
    color: #2e2e2e !important;
    border: none;
}
button.fc-prev-button.fc-button.fc-button-primary{
    border-radius: 5px !important;
    width: 32px;
    height: 32px;
}
button.fc-today-button.fc-button.fc-button-primary {
    display: none;
}

.fc-button:focus,
.fc-button:active {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}


/* MOAL */
button.btn-close {
    margin-left: 0 !important;
}

.modal-content {
    padding: 40px;
    padding-bottom: 20px;
}

.modal-header {
    border: 0;
    padding-top: 0;
}

.modal-header h4 {
    color: #000;
    font-weight: bold;
}

.modal-title.h4 {
    font-weight: bold;
}

.modal-footer {
    border: none;
}

.modal-body .form-control {
    width: 100%;
    padding: 14px;
}
section.detailed__calendar button.btn-close {
    color: red !important;
    opacity: 1;
}

.search__btn .btn-primary {
    background: transparent;
    border: transparent;
    padding: 0;
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search__btn .btn-primary i {
    color: #dee2e6;
    font-size: 24px;
    margin-right: 10px;
}

.search__btn .form-control {
    margin: 0;
    min-height: 46px;
    border: none;
    background: transparent;
    min-width: 250px;
}

.search__btn {
    background: #fff;
    display: inline-flex;
    padding: 0px 20px;
    border-radius: 6px;
    border: 1px solid #eee;
}
section.detailed__calendar button.btn.btn-primary:hover {
    border: none;
    padding: 0;
}
.search__btn {
    top: -6px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.fc .fc-daygrid-day-frame {
    position: relative;
    min-height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.event__calendar.position-relative .fc .fc-daygrid-day-number {
    font-size: 16px;
}
.event__calendar.position-relative .fc .fc-daygrid-day-frame {
    height: 64px;
    width: 63px;
}
.event__calendar.position-relative .fc .fc-toolbar-title {
    font-size: 21px;
}
.event__calendar.position-relative button.fc-addEvent-button.fc-button.fc-button-primary {
    font-size: 18px;
    z-index: 9;
}
.event__calendar.position-relative button.fc-search-button.fc-button.fc-button-primary {
    font-size: 17px;
    z-index: 9;
}
.homeEvent__calendar .fc-theme-standard td, .fc-theme-standard th {
    border: none !important;
    height: 30px;
}
.event__calendar.position-relative .fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #ddd;
    height: 30px;
}
.event__calendar.position-relative .fc .fc-scroller {
    overflow: hidden !important;
}

.event__calendar.position-relative thead {
    display: none;
}
.event__calendar.position-relative .fc .fc-daygrid-day.fc-day-today {
    background-color: var(--color-primary);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.event__calendar.position-relative .fc .fc-view-harness {
    flex-grow: 1;
    position: relative;
    min-height: 422px !important;
}
.event__calendar.position-relative .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin: 0;
}
.event__calendar.position-relative span.fc-icon.fc-icon-bi.bi-filter-right {
    font-size: 40px;
    position: absolute;
    top: -5px;
}
span.fc-icon.fc-icon-bi.bi-search {
    margin-left: 14px;
}
.event__calendar.position-relative .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    position: relative;
}

.event__calendar.position-relative .search__btn {
    top: 0;
    position: initial;
    left: 0;
    right: 0;
    transform: matrix(1, 0, 0, 1, 0, 0);
    margin-bottom: 20px;
}

/* HOME UPCOMING EVENTS */
.home__upcoming__events .title h3 {
    color: #BFBFBF;
    font-size: 18px;
    margin-top: 10px;
    padding-bottom: 22px;
}

.home__upcoming__events .tab__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home__upcoming__events .tab__grid .title h2 {
    font-weight: bold;
    font-size: 20px;
}

.home__upcoming__events .tab__grid .title p {
    color: #BFBFBF;
    margin: 0;
}

.home__upcoming__events .tab__grid {
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}

.home__upcoming__events .left__btn .btn-time {
    background: #14aa72;
    color: #fff;
    border: none;
    padding: 6px 20px;
    margin-left: 10px;
    min-height: 40px;
    border-radius: 3px;
}

.home__upcoming__events button.btn-arrow {background: #2E2E2E 0% 0% no-repeat padding-box;box-shadow: 0px 0px 5px #2E2E2E4D;border-radius: 3px;opacity: 1;padding: 6px 10px;min-height: 40px;}

.home__upcoming__events .tab__grid:last-child {
    border: none;
}
.homeEvent__calendar {

}