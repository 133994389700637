section.detailed__calendar {
    position: relative;
    margin-top: 60px;
}

section.detailed__calendar .mbsc-ios.mbsc-calendar {
    background: transparent;
    color: #000;
    padding: 36px;
}
section.detailed__calendar  .mbsc-ios.mbsc-calendar-cell {
    background: transparent;
    border-color: transparent;
    color: #000;
}

section.detailed__calendar  .mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-day-inner {
    min-height: 5.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-marks {
    padding: 0 0.75em;
    text-align: center !important;
}

section.detailed__calendar  .mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-day-text {
    height: 66px;
    line-height: 1.375em;
    width: 66px;
}