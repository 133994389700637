@media only screen and (max-width: 600px) {
    .main_dashboard {
        width: 100%;
        background-size: cover;
        min-height: 88vh;
    }
    section.tsd__details{
        height: 225px;
    }
    section.tsd__details .page__title .icon img {
        height: 43px;
    }
    .slideCount {
        position: absolute;
        left: 18px;
        z-index: -1;
        top: -7px;
    }
    .slideCount {
        position: absolute;
        left: 18px;
        z-index: -1;
        top: -7px;
    }
    
    .main_dashboard .message__section span{
        display: none;
    }
    .main_dashboard .message__section .news__marquee .title {
        min-width: 17px;
    }
    .main_dashboard .message__section .news__marquee .viewAll a {
        font-size: 12px;
    }
    .main_dashboard .message__section .news__marquee .viewAll {
        min-width: 58px;
    }
    button.btn.btn-search {
        padding: 13px 15px;
    }
    .header .left__nav {
        display: none ;
    }
    .header a.logo img{
        height: 50px;
    }
    .navbar-collapse.show {
        margin-top: 20px;
    }
    .navbar-default.navbar-trans .nav-item{
        padding: 10px;
    }
    .main_dashboard img.slick-arrow.slick-prev {
        right: 0;
    }
    .main_dashboard img.slick-arrow.slick-next {
        left: 0;
    }
    .main_dashboard .advanced__search label.form-label {
        margin-top: 0;
    }
    section.news_poll .news__grid {
        padding: 0;
    }
    .default__heading h1 {
        font-size: 22px;
    }
    button#dropdown-item-button {
        min-width: 130px;
        padding: 7px 8px;
    }
    section.news_poll .slick-prev {
        margin-top: 8px;
    }
    section.news_poll .slick-next {
        margin-top: 8px;
    }
    .default__heading{
        margin-top: 20px;
    }
    .pl-0 {
        padding: initial;
    }
    .events__announcements__grid .card img {
        height: 215px;
    }
    .events__announcements__grid .card {
        margin: 20px;
    }
    .events__announcements__grid .card-body .news__announcement{
        opacity: 1;
        display: flex;
        align-items: center;
    }
    .btn.btn-outline-primary {
        padding: 10px 18px;
    }
    .view__more {
        margin-left: 20px;
    } 
    .training__suggestions .trainingGrids{
        margin: 20px;
    }   
    .training__suggestions .trainingGrids .title p {
        font-size: 11px;
    }
    .training__suggestions .suggestionsGrid {
        padding-right: 0;
        margin: 20px;
    }
    .default__heading {
        margin: 0 20px;
        padding-bottom: 0 !important;
    }
    .events__announcements__grid .card-body .news__announcement button {
        margin-left: 5px;
    }
    .vaccancies .vacancy__grid .apply {
        display: flex;
        align-items: start;
        justify-content: space-around;
        flex-direction: column;
    }
    .vaccancies .vacancy__grid .apply p {
        padding-bottom: 10px;
    }
    .training__suggestions .suggestionsGrid .uploadBtn img {
        height: 14px;
        padding-right: 6px;
    }
    .btn.btn-common {
        min-width: 100px;
        font-size: 16px;
    }
    .btn.btn-danger{
        min-width: 100px;
        font-size: 16px;
    }
    .training__suggestions .suggestionsGrid .uploadBtn {
        height: 50px;
        min-width: 100px;
        font-size: 16px;
    }
    button.btn-sm.btn-primary {
        min-width: 110px;
    }
    section.tsd__reports.bg-white.pt-3 {
        display: none;
    }
    .add__comment__section {
        min-height: 133vh;
    }
    .upload {
        display: flex;
    }
    .quick__access .slick-list {
        padding-left: 0;
    }
    .initiatives__section .initiativesGrid {
        padding: 20px;
        border-bottom: 1px solid #dee2e6;
    }
    .evaluation__section {
        width: 100%;
    }
    .evaluation__section .evaluationActions button {
        font-size: 16px !important;
        color: #fff;
        padding: 10px 10px;
        width: 134px !important;
    }
    .evaluation__section .card {
        padding: 30px;
        margin-top: 30px;
    }
    .transport {
        flex-direction: column;
    }
    .transport h4 i{
        display: none;
    }
    .btn-upload {
        color: var(--color-default);
        background-color: #F8F9FA;
        padding: 11px 25px;
        border-radius: 6px;
        font-size: 13px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    select.form-select.sm-select {
        background-color: #F8F9FA;
        padding: 11px 25px;
        border-radius: 6px;
        font-size: 13px;
        border: none;
        padding-left: 20px;
        margin-bottom: 20px;
        text-align: center;
    }
    button.btn.btn-primary.btn-post {
        border-radius: 7px;
        background: #31D68F;
        color: #000000ba;
        padding: 9px 36px;
        border-radius: 6px;
        font-size: 20px;
        border: none;
        width: 100%;
    }
    .communication .comments__grid .comment img {
        width: 100%;
        height: 231px;
    }
    .homeCalendarSearch {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: -56px;
        right: 0;
        z-index: 9;
        top: auto;
    }
    .event__calendar.position-relative button.fc-addEvent-button.fc-button.fc-button-primary{
        z-index: 8;
    }
    .modal-body .form-control {
        width: 100%;
        padding: 9px;
        margin-bottom: 10px;
    }
    .communication__form .card__form .submit__btn {
        flex-direction: column;
    }
    .communication__form  .upload-btn-wrapper{
        width: 100%;
    }
    .accordion {
        margin: 0 27px;
    } 
    section.image__gallery {
        padding-top: 0;
    }
    section.image__gallery .gallery__image {
        margin-bottom: 0;
        margin-top: 30px;
    }
    section.image__gallery  a.btn.btn-primary {
        margin-top: 30px;
    }
    .footer__logo {
        text-align: center;
        margin-bottom: 30px;
    }
    section.footer {
        padding-top: 20px;
    }
    .add__comment .form-control {
        margin-bottom: 0;
    }
    .communication {
        border-left: none;
        padding-left: 0;
    }
    .submit {
        padding-top: 18px;
    }
    section.news__details button#dropdown-item-button {
        margin-left: 55px;
    }
    section.faq.vaccancy__lists .accordion-item {
        padding: 0px;
    }
    section.tsd__about__rules {
        margin: 30px;
        margin-top: 0;
    }
    button.fc-search-button.fc-button.fc-button-primary {
        padding: 0;
        font-size: 17px;
        font-weight: 900;
        margin-left: 0;
    }
    .fc .fc-toolbar-title {
        font-size: 20px;
    }
    .modal-70w {
        width: 95%;
        max-width: none!important;
    }
    .modal-content {
        padding: 22px;
        padding-bottom: 20px;
    }
    .tarainingModal .btn-close {
        left: 16px;
        top: 23px;
    }
    section.news__details .news__image img {
        width: 100%;
        height: 170px;
    }
    section.news__details .news__image {
        margin-bottom: 0;
    }
    section.news__details .news__detailsCard {
        margin-top: 0;
    }
    .news__detailsCard .announcement__title {
        margin: 44px 0 15px 0;
        flex-direction: column;
    }
    section.event__details .eventDetails .news__image img {
        width: 100%;
        height: 190px;
    }
    .initiatives__section .initiativesGrid .title {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        padding-top: 7px;
        flex-direction: column;
    }
    .initiatives__section .initiativesGrid .title h2 {
        margin-bottom: 6px;
    }
    .leaderboard__btns {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    section.news__details .default__heading{
        margin-bottom: 20px;
    }
    section.news__details .news__title h2 {
        margin-top: 20px;
    }
    section.news__details .news__title ul {
        flex-direction: column;
    }
}

/* TABLET */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .col-tab-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-tab-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col-tab-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .main_dashboard {
        min-height: 64vh;
    }
    section.news_poll .news__grid {
        padding: 0;
    }
    .default__heading h1 {
        font-size: 20px;
    }
    .poll__details {
        margin-top: 0;
    }
    .events__announcements__grid .card-body .news__announcement {
        opacity: 1;
        display: flex;
    }
    button#dropdown-item-button {
        min-width: 145px;
    }
    .btn.btn-outline-primary {
        padding: 10px 12px;
    }
    .events__announcements__grid .card img {
        width: 100%;
        height: 175px;
    }
    .btn.btn-common {
        min-width: 88px;
        font-size: 16px;
    }
    .btn.btn-danger {
        min-width: 88px;
        font-size: 16px;
    }
    .training__suggestions .suggestionsGrid .uploadBtn {
        min-width: 88px;
        font-size: 16px;
    }
    .upload {
        display: flex;
    }
    .training__suggestions .suggestionsGrid .uploadBtn img {
        height: 14px;
        padding-right: 10px;
    }
    .vaccancies .vacancy__grid .apply {
        flex-direction: column;
        align-items: start;
    }
    .vaccancies .vacancy__grid .apply p {
        padding-bottom: 11px;
    }
    .quick__access .slick-list {
        padding-left: 0;
    }
    .initiatives__section .initiativesGrid .title {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    .initiatives__section .initiativesGrid .title h2 {
        margin-bottom: 10px;
    }
    .evaluation__section {
        width: 100%;
        margin-right: auto;
    }
    .evaluation__section .card {
        padding: 18px;
        margin-top: 50px;
    }
    .communication__form .card__form .submit__btn {
        width: 100%;
    }    
    .upload-btn-wrapper{
        width: 100%;
        margin-bottom: 6px;
    }
    select.form-select.sm-select{
        text-align: center;
    }
    button.btn.btn-primary.btn-post {
        border-radius: 7px;
        background: #31D68F;
        color: #000000ba;
        padding: 11px 36px;
        border-radius: 6px;
        font-size: 16px;
        border: none;
        width: 100%;
    }
    .homeCalendarSearch {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: -55px;
        right: 0;
        z-index: 9;
        top: auto;
    }
    .event__calendar.position-relative button.fc-addEvent-button.fc-button.fc-button-primary {
        font-size: 18px;
        z-index: 8;
    }
    .add__comment__section {
        min-height: 119vh;
    }
    section.image__gallery .gallery__image img {
        height: 140px;
    }
    section.upcoming__events .tab__content {
        margin-top: 30px;
    }
    section.event__details .eventDetails .news__image img {
        width: 100%;
        height: 300px;
    }
    section.news__details .news__image img {
        width: 100%;
        height: 300px;
    }
}

/* IPAD PRO */
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .col-tab-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-tab-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col-tab-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .main_dashboard {
        min-height: 64vh;
    }
    section.news_poll .news__grid {
        padding: 0;
    }
    .default__heading h1 {
        font-size: 20px;
    }
    .poll__details {
        margin-top: 0;
    }
    .events__announcements__grid .card-body .news__announcement {
        opacity: 1;
        display: flex;
    }
    button#dropdown-item-button {
        min-width: 145px;
    }
    .btn.btn-outline-primary {
        padding: 10px 12px;
    }
    .events__announcements__grid .card img {
        width: 100%;
        height: 175px;
    }
    .btn.btn-common {
        min-width: 88px;
        font-size: 16px;
    }
    .btn.btn-danger {
        min-width: 88px;
        font-size: 16px;
    }
    .training__suggestions .suggestionsGrid .uploadBtn {
        min-width: 88px;
        font-size: 16px;
    }
    .upload {
        display: flex;
    }
    .training__suggestions .suggestionsGrid .uploadBtn img {
        height: 14px;
        padding-right: 10px;
    }
    .vaccancies .vacancy__grid .apply {
        flex-direction: column;
        align-items: start;
    }
    .vaccancies .vacancy__grid .apply p {
        padding-bottom: 11px;
    }
    .quick__access .slick-list {
        padding-left: 0;
    }
    .initiatives__section .initiativesGrid .title {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    .initiatives__section .initiativesGrid .title h2 {
        margin-bottom: 10px;
    }
    .evaluation__section {
        width: 100%;
        margin-right: auto;
    }
    .evaluation__section .card {
        padding: 18px;
        margin-top: 50px;
    }
    .communication__form .card__form .submit__btn {
        width: 100%;
    }    
    .upload-btn-wrapper{
        width: 100%;
        margin-bottom: 6px;
    }
    select.form-select.sm-select{
        text-align: center;
    }
    button.btn.btn-primary.btn-post {
        border-radius: 7px;
        background: #31D68F;
        color: #000000ba;
        padding: 11px 36px;
        border-radius: 6px;
        font-size: 16px;
        border: none;
        width: 100%;
    }
    .homeCalendarSearch {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: -55px;
        right: 0;
        z-index: 9;
        top: auto;
    }
    .event__calendar.position-relative button.fc-addEvent-button.fc-button.fc-button-primary {
        font-size: 18px;
        z-index: 8;
    }
    .add__comment__section {
        min-height: 119vh;
    }
    section.image__gallery .gallery__image img {
        height: 140px;
    }
    section.upcoming__events .tab__content {
        margin-top: 30px;
    }
    section.event__details .eventDetails .news__image img {
        width: 100%;
        height: 300px;
    }
    section.news__details .news__image img {
        width: 100%;
        height: 300px;
    }
}

/* 13 INCH SCREEN */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {}