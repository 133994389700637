
.navbar-toggle-box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    padding: 10px;
  }
  
  .navbar-toggle-box i {
    line-height: 0;
  }
  
  /*--------------------------------------------------------------
  # Navbar
  --------------------------------------------------------------*/
  .navbar-default {
    transition: all 0.5s ease-in-out;
    background-color: #ffffff;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 1px 2px 15px rgba(100, 100, 100, 0.3);
    padding: 13px 0;
  }
  .header .dropdown-toggle::after {
    display: none;
}
  .navbar-default .nav-search {
    color: #000000;
    font-size: 1.5rem;
  }
  
  .navbar-default.navbar-reduce {
    box-shadow: 1px 2px 15px rgba(100, 100, 100, 0.3);
  }
  
  .navbar-default.navbar-trans,
  .navbar-default.navbar-reduce {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .navbar-default.navbar-trans .nav-item,
  .navbar-default.navbar-reduce .nav-item {
    position: relative;
    padding-right: 10px;
    padding-bottom: 8px;
    margin-left: 0;
  }
  
  @media (min-width: 768px) {
  
    .navbar-default.navbar-trans .nav-item,
    .navbar-default.navbar-reduce .nav-item {
      margin-left: 15px;
    }
  }
  
  .navbar-default.navbar-trans .nav-link,
  .navbar-default.navbar-reduce .nav-link {
    font-size: 1.1rem;
    color: #000000;
    font-weight: 600;
    letter-spacing: 0.03em;
    transition: all 0.1s ease-in-out;
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
  
  .navbar-default.navbar-trans .nav-link:before,
  .navbar-default.navbar-reduce .nav-link:before {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 0;
    background-color: #2eca6a;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out 0.3s;
  }
  
  .navbar-default.navbar-trans .nav-link:hover,
  .navbar-default.navbar-reduce .nav-link:hover {
    color: #000000;
  }
  
  .navbar-default.navbar-trans .nav-link:hover:before,
  .navbar-default.navbar-reduce .nav-link:hover:before {
    transform: scaleX(1);
    transform-origin: left;
  }
  
  .navbar-default.navbar-trans .show>.nav-link:before,
  .navbar-default.navbar-trans .active>.nav-link:before,
  .navbar-default.navbar-trans .nav-link.show:before,
  .navbar-default.navbar-trans .nav-link.active:before,
  .navbar-default.navbar-reduce .show>.nav-link:before,
  .navbar-default.navbar-reduce .active>.nav-link:before,
  .navbar-default.navbar-reduce .nav-link.show:before,
  .navbar-default.navbar-reduce .nav-link.active:before {
    transform: scaleX(1);
  }
  
  .navbar-default.navbar-trans .nav-link:before {
    background-color: #2eca6a;
  }
  
  .navbar-default.navbar-trans .nav-link:hover {
    color: #000000;
  }
  
  .navbar-default.navbar-trans .show>.nav-link,
  .navbar-default.navbar-trans .active>.nav-link,
  .navbar-default.navbar-trans .nav-link.show,
  .navbar-default.navbar-trans .nav-link.active {
    color: #000000;
  }
  
  .navbar-default.navbar-reduce {
    transition: all 0.5s ease-in-out;
    padding-top: 19px;
    padding-bottom: 19px;
  }
  
  .navbar-default.navbar-reduce .nav-link {
    color: #000000;
  }
  
  .navbar-default.navbar-reduce .nav-link:before {
    background-color: #2eca6a;
  }
  
  .navbar-default.navbar-reduce .nav-link:hover {
    color: #000000;
  }
  
  .navbar-default.navbar-reduce .show>.nav-link,
  .navbar-default.navbar-reduce .active>.nav-link,
  .navbar-default.navbar-reduce .nav-link.show,
  .navbar-default.navbar-reduce .nav-link.active {
    color: #000000;
  }
  
  .navbar-default.navbar-reduce .navbar-brand {
    color: #000000;
  }
  
  .navbar-default .dropdown .dropdown-menu {
    border-top: 0;
    border-left: 4px solid #2eca6a;
    border-right: 0;
    border-bottom: 0;
    transform: translate3d(0px, 40px, 0px);
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    transition: all 0.5s cubic-bezier(0.3, 0.65, 0.355, 1) 0s, opacity 0.31s ease 0s, height 0s linear 0.36s;
    margin: 0;
    border-radius: 0;
    padding: 12px 0;
  }
  
  @media (min-width: 768px) {
    .navbar-default .dropdown .dropdown-menu {
      border-top: 4px solid #2eca6a;
      border-left: 0;
      display: block;
      position: absolute;
      box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(20, 19, 18, 0.1);
    }
  }
  
  .navbar-default .dropdown .dropdown-menu .dropdown-item {
    padding: 12px 18px;
    transition: all 500ms ease;
    font-weight: 600;
    text-align: right;
  }
  
  .navbar-default .dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #ffffff;
    color: #2eca6a;
    transition: all 500ms ease;
  }
  
  .navbar-default .dropdown .dropdown-menu .dropdown-item.active {
    background-color: #ffffff;
    color: #2eca6a;
  }
  
  .navbar-default .dropdown:hover .dropdown-menu {
    transform: translate3d(0px, 0px, 0px);
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=1);
  }
  
  /*------/ Hamburger Navbar /------*/
  .navbar-toggler {
    position: relative;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: 0;
  }
  
  .navbar-toggler span {
    display: block;
    background-color: var(--color-primary);
    height: 3px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    transform: rotate(0deg);
    left: 0;
    opacity: 1;
  }
  
  .navbar-toggler span:nth-child(1),
  .navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 10px;
    transform: rotate(135deg);
    opacity: 0.9;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: transparent;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    transform: rotate(-135deg);
    opacity: 0.9;
  }
  
  /*======================================
  //--//-->   BOX COLAPSE
  ======================================*/
  .box-collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 1040;
    background-color: #ffffff;
    transform: translateX(100%);
    transition: all 0.6s ease;
  }
  
  @media (min-width: 768px) {
    .box-collapse {
      width: 50%;
    }
  }
  
  .box-collapse .title-box-d {
    top: 30px;
    left: 60px;
    opacity: 0;
    transition: all 1s ease;
    transition-delay: 0.3s;
  }
  
  @media (max-width: 575px) {
    .box-collapse .title-box-d {
      left: 35px;
    }
  }
  
  @media (max-width: 575px) {
    .box-collapse .title-box-d .title-d {
      font-size: 1.3rem;
    }
  }
  
  .box-collapse-wrap {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 6.5rem;
    bottom: 5rem;
    padding-left: 10%;
    padding-right: 10%;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    transform: translateY(3rem);
    transition: transform 0.5s 0.5s ease, opacity 0.5s 0.5s ease;
  }
  
  .box-collapse-open .click-closed {
    visibility: visible;
  }
  
  .box-collapse-open .box-collapse {
    transform: translateX(0);
    /* ve11adan */
    box-shadow: 0 0 65px rgba(0, 0, 0, 0.07);
    opacity: 1;
  }
  
  .box-collapse-open .box-collapse .title-box-d {
    transform: translate(0);
    opacity: 1;
  }
  
  .box-collapse-open .box-collapse-wrap {
    transform: translate(0);
    opacity: 1;
  }
  
  .box-collapse-closed .box-collapse {
    opacity: 0.7;
    transition-delay: 0s;
  }
  
  .box-collapse-closed .box-collapse .title-box-d {
    opacity: 0;
    transition-delay: 0s;
  }
  
  .box-collapse-closed .box-collapse .form-a {
    opacity: 0;
    transition-delay: 0s;
  }
  
  .click-closed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    z-index: 1035;
    background-color: #000000;
    opacity: 0.4;
  }
  
  .close-box-collapse {
    position: absolute;
    z-index: 1050;
    top: 2rem;
    font-size: 3.5rem;
    line-height: 1;
    color: #000000;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .right-boxed {
    right: 4.2857rem;
  }

  
  
.header .nav__icons {
    background: var(--color-secondary);
    padding: 33px 15px;
    min-width: 130px;
    min-height: 90px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.header .nav__icons img {
    height: 16px;
}

.header .left__nav {
    display: flex;
    align-items: center;
}

.header .left__nav img.notification {
    height: 20px;
    margin: 10px;
}

.header .admin__image p {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
}

.header .admin__image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header .admin__image img {
    height: 30px !important;
}
.header .navbar-default a{
    color: var(--color-default);
    font-size: 16px;
}
.header .navbar-default a.active{
  color: var(--color-primary);
  font-weight: 700;
  border-bottom: 3px solid var(--color-primary);
}
.header a.logo img {
    padding-right: 100px;
}

.header ul.navbar-nav {
    padding-right: 0;
}

/* NEW STYLES */
